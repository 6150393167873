<template>
  <section
    id="pudin"
  >
  <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-row
            align="center"
            justify="center"
          >
            <v-card
              v-for="(product, i) in productos"
              :key="i"
              class="mx-auto mt-3"
              width="580"
              elevation="24"
            >
              <v-carousel
                hide-delimiter-background
                delimiter-icon="mdi-minus"
                height="630"
              >
                <v-carousel-item
                  container
                  v-for="(imagen, n) in product.imagenes"
                  :key="n"
                  :src="imagen.src"
                  :lazy-src="imagen.src"
                >
                </v-carousel-item>
              </v-carousel>
              <v-card-title>
                {{ product.nombre }}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-3 d-none d-lg-block d-print-block"
                      icon
                      @click="product.dialog = true"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-btn
                      color="orange"
                      fab
                      small
                      >
                        <v-icon color="white">mdi-fullscreen</v-icon>
                      </v-btn>
                    </v-btn>
                  </template>
                  <span>Pantalla completa</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      @click="product.mostrar = !product.mostrar"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-btn
                      color="primary"
                      fab
                      small
                      >
                        <v-icon color="white">{{ product.mostrar ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                      </v-btn>
                    </v-btn>
                  </template>
                  <span>Descripción</span>
                </v-tooltip>
              </v-card-actions>
              <v-expand-transition>
                <div v-show="product.mostrar">
                  <v-divider></v-divider>
                  <v-card-text>
                    {{ product.des }}
                   <span v-html="product.disp"></span>
                  </v-card-text>
                </div>
              </v-expand-transition>
              <v-dialog
                v-model="product.dialog"
                :key="product.disp"
                fullscreen
                transition="fade-transition"
              >
              <v-card
                color="grey darken-4"
                width="1080"
                dark
              >
                <v-app-bar
                  color="primary"
                  fixed
                  flat
                >
                <h2>{{product.nombre}}</h2>
                  <v-spacer />
                  <v-btn
                    class="mx-1 mx-md-3"
                    icon
                    small
                    @click="product.dialog = !product.dialog"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-app-bar>
                  <v-responsive min-height="100vh">
                    <v-container
                      class="pa-0 fill-height"
                      fluid
                    >
                      <v-carousel
                        hide-delimiters
                        height="950"
                      >
                        <v-carousel-item
                          v-for="(pic, j) in product.imagenes"
                          :key="j"
                        >
                          <v-img
                            contain
                            :aspect-ratio="16/9"
                            :src="pic.src"
                            :lazy-src="pic.src"
                            :alt="pic.alt"
                          ></v-img>
                        </v-carousel-item>
                      </v-carousel>
                    </v-container>
                  </v-responsive>
                </v-card>
              </v-dialog>
            </v-card>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
  import cons from '@/assets/cons.jpg'
  import consCab from '@/assets/consCab.jpg'
  import consCent from '@/assets/consCent.jpg'
  import consGamb from '@/assets/consGamb.jpg'
  import consTx from '@/assets/consTx.jpg'
  export default {
    name: 'Conservas',
    metaInfo: { title: 'Conservas' },
    data: () => ({
      show: false,
      productos: [
        {
          nombre: 'Tarros de conservas',
          mostrar: false,
          dialog: false,
          des: 'Nuestras exquisiteces más destacables en pequeñas tarrinas para su total disfrute.',
          disp: '<br>Diponibilidad<br><ul><li>110g: Caja de 12 unidades</li><li>200g: Caja de 6 unidades</li></ul>Conservación: a temperatura ambiente<br>Caducidad: 36 meses',
          imagenes: [
            {
              src: consCab,
              alt: 'Conserva de pudin de pez de roca servida en bote de vidrio',
            },
            {
              src: consCent,
              alt: 'Conserva de pudin de centollo servida en bote de vidrio',
            },
            {
              src: consGamb,
              alt: 'Conserva de gambas servida en bote de vidrio',
            },
            {
              src: consTx,
              alt: 'Conserva de txangurro servida en bote de vidrio',
            },
            {
              src: cons,
              alt: 'Conjunto de conservas servidas por cantdelimar',
            },
          ],
        },
      ],
    }),
  }
</script>
